<template>
  <fragment>
    <app-header title="Camera Wall">
      <template #actions>
        <button type="button" class="btn btn-primary" @click="nextPage()" >
          <i class="far fa-angle-right"></i> Next Page
        </button>
        &nbsp;
        &nbsp;
        <button type="button" class="btn btn-primary" v-c-modal:viewSettingsDrawer>
          <i class="far fa-cog"></i> View Settings
        </button>
      </template>
    </app-header>

    <ul class="camera-wall">
      <camera-wall-list-item v-for="index in 16" :key="index" :camera="cameraForIndex(index)" @viewSettings="openDrawer" />
    </ul>

    <camera-wall-settings-drawer ref="viewSettingsDrawer" />
  </fragment>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/user/settings'
import CameraWallSettingsDrawer from './CameraWallSettingsDrawer.vue'
import CameraWallListItem from './CameraWallListItem.vue'
import { call } from 'vuex-pathify'

export default {
  components: {
    CameraWallSettingsDrawer,
    CameraWallListItem
  },

  data () {
    return {
      timer: null,
      page: 1,
      settingsTimer: null,
      pageTimer: null,
    }
  },

  computed: {
    ...helperComputed,
  },

  mounted () {
    this.settingsTimer = setInterval(() => {
      this.getUserSettings({ page: this.page })
    }, 300000)

    this.pageTimer = setInterval(() => {
      this.nextPage()
    }, 60000)
  },

  created () {
    this.getUserSettings({ page: this.page })
  },

  beforeDestroy () {
    this.resetSettings()
    clearInterval(this.settingsTimer)
    clearInterval(this.pageTimer)
  },

  methods: {
    ...helperMethods,
    ...call('settings', ['getUserSettings']),

    cameraForIndex (index) {
      const arrayIndex = index - 1
      if (arrayIndex < this.$store.state.settings.settings.length) {
        return this.$store.state.settings.settings[arrayIndex]
      }

      return null
    },

    openDrawer () {
      this.$refs.viewSettingsDrawer.open()
    },

    nextPage () {
      this.page++
      if (this.page > (this.$store.state.settings.total - 1) / 16 + 1) {
        this.page = 1
      }
      this.getUserSettings({ page: this.page })
    }
  }
}
</script>
