<template>
  <div
    role="dialog"
    class="modal modal-drawer-list-scroll fade fixed-end"
    aria-modal="true"
  >
    <div
      role="document"
      class="modal-dialog modal-dialog-vertical dw-11 dw-md-8 dw-lg-6 dw-xl-4"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">View Settings</h2>
          <button
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            class="btn-close"
          ></button>
        </div>

        <div class="modal-body pt-0 pb-0">
          <div class="mb-2">
            <label class="form-label">Search</label>
            <input v-model="filters.search" type="text" class="form-control">
          </div>

          <div class="mb-2">
            <label>Screen Tags</label>
            <screen-tag-select holder="All" v-model="selectedTags" @input="updateTags"/>
          </div>

          <div v-if="$isA('admin')" class="mb-2">
            <label>Media Owner</label>

            <media-owner-select v-model="filters.mediaOwner" prependAll/>
          </div>

          <div class="mb-2">
            <label>Status</label>

            <c-input
              as="multiselect"
              placeholder
              :options="statusOptions"
              :searchable="false"
              :preselect-first="true"
              :show-labels="false"
              :allow-empty="false"
              label="name"
              v-model="status"
            >
            </c-input>

          </div>

          <div class="row mt-3">
            <div class="col">
              <h3 class="m-0">Select Screens:</h3>
            </div>
            <div class="col-auto d-flex align-items-center">
              <p class="text-muted">{{ selectedCameras.length }} Selected</p>
            </div>
          </div>
        </div>
        <c-infinite-scroll @load="load" target-self as="div" class="scroll-container border-top" :manual-poll="isOpen">
          <ul class="list-data list-data-screens no-links mt-0 has-multi-checkbox">
            <li v-for="item in cameras" :key="item.id">
              <div class="form-check">
                <input :id="`checkbox-list-${item.id}`" type="checkbox" class="form-check-input" :value="item.id" v-model="updateSelectedCameras" >
                <label :for="`checkbox-list-${item.id}`"></label>
              </div>
              <p>{{ item.screen.name }} - {{ item.name }}</p>
              <p class="text-muted small">{{ item.screen.address }}</p>
              <i data-bs-toggle="tooltip" title="" class="indicator far fa-fw" :class="indicatorClasses[item.screen.status]"></i>
            </li>
          </ul>
        </c-infinite-scroll>
        <div class="modal-footer">
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-outline-info"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/cameras'
import { CModal } from '@chameleon/chameleon-vue'
import { call } from 'vuex-pathify'
import ScreenTagSelect from '@/components/forms/ScreenTagSelect'
import MediaOwnerSelect from '@/components/media-owners/MediaOwnerSelect'
import { useQueryParamFilters, compareQueryParams } from '@codium/codium-vue'
import { map } from 'lodash'

export default {
  extends: CModal,

  mixins: [useQueryParamFilters()],

  components: {
    ScreenTagSelect,
    MediaOwnerSelect
  },

  data () {
    return {
      isOpen: false,
      loading: false,
      error: null,
      filters: {
        search: null,
        tags: null,
        mediaOwner: null,
        status: null
      },
      indicatorClasses: {
        'No Issues': 'fa-check text-success',
        'Communication Error': 'fa-wifi-slash text-warning',
        Fault: 'fa-exclamation-triangle text-danger'
      },
      statusOptions: [
        { name: 'All', value: 'all' },
        { name: 'No Issues', value: 1 },
        { name: 'Communication Error', value: 2 },
        { name: 'Fault', value: 4 }
      ],
      selectedTags: [],
      selectedCameras: []
    }
  },

  watch: {
    queryParams: compareQueryParams(async function (params) {
      if (this.isOpen) {
        await this.getSelectedCameraIds({ params, fresh: true })
      }
    })
  },

  beforeDestroy () {
    this.resetCameras()
  },

  async mounted () {
    this.addEventListener('open', async () => {
      // reset form
      this.resetCameras()

      setTimeout(() => {
        this.isOpen = true
      }, 300)
    })

    this.addEventListener('close', async () => {
      setTimeout(() => {
        this.isOpen = false
      }, 300)
    })
  },

  computed: {
    ...helperComputed,

    updateSelectedCameras: {
      get () {
        return this.selectedCameras
      },
      set (value) {
        this.selectedCameras = value
        this.save()
      }
    },

    status: {
      get () {
        return this.statusOptions.find(({ value }) => value === this.filters.status)
      },

      set ({ value }) {
        this.filters.status = value
      }
    },
  },

  methods: {
    ...helperMethods,
    ...call('settings', ['getUserSettings']),
    ...call('cameras', ['getSelectedCameraIds']),
    storeSetting: call('user/settings/storeSetting'),

    async save () {
      await this.storeSetting({ cameras: this.selectedCameras })
      this.getUserSettings({ page: 1 })
    },

    async load (done) {
      const { params } = this

      try {
        await this.getSelectedCameraIds({ params })
        this.selectedCameras = map(this.$store.state.cameras.selectedCameraIds, (id) => { return id })

        done()
      } catch {
        done(true)
      }
    },

    updateTags () {
      this.filters.tags = this.selectedTags.map(({ id }) => id)
    }
  }
}
</script>
